body {
  margin: 0;
  font-family: 'メイリオ', Meiryo,'ヒラギノ角ゴシック','Hiragino Sans','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','Arial','Arial','Arial',sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

blockquote {
  position: relative;
  padding: 10px 15px 10px 15px;
  margin-inline-start: 5px;
  margin-inline-end: 5px;
  box-sizing: border-box;
  /* font-style: italic; */
  background: #f5f5f5;
  color: #777777;
  border-left: 4px solid #9dd4ff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
}

blockquote p {
  padding: 0;
  margin: 7px 0;
  line-height: 1.7;
}

blockquote cite {
  display: block;
  text-align: right;
  color: #888888;
  font-size: 0.9em;
}

#document.timeline-Widget {
  max-width: 100% !important;
  width: 100% !important;
}

div.timeline-Widget {
  max-width: 100% !important;
  width: 100% !important;
}

.timeline {
  max-width: 100% !important;
  width: 100% !important;
}
/* ここから下を追記 */
/* .SandboxRoot.env-bp-330 .timeline-Tweet-text {
    font-size: 20.5pt !important;
    line-height: 24pt!important;
    }

.SandboxRoot.env-bp-820 .timeline-Tweet-text {
    font-size: 10.5pt !important;
    line-height: 14pt!important;
    } */
.SandboxRoot.env-bp-330 .timeline-Tweet-text {
  font-size: 20px;
  line-height: 24px;
}

/* blockquote.twitter-tweet {
  display: inline-block;
  font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
  border-color: #eee #ddd #bbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  padding: 0 16px 16px 16px;
  max-width: 468px;
  background-color: antiquewhite;
}

blockquote.timeline-Tweet-text p {
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
}

blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}

blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
} */
